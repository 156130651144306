import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledBackgroundSection from "../components/bg-image"

const AboutMePage = () => (
  <Layout>
    <SEO title="About me" />
    <div className="about-page-row">
      <div className="about-page-col container">
        <h1>The need for speed</h1>

        <p>
          {" "}
          If the page is loaded only after 3 seconds, a large part of the
          visitors leaves the website. Also, nearly 80 percent of users who have
          had poor performance experience avoid the pages permanently and never
          return. Delaying webpage load time by one second reduces customer
          satisfaction by approximately 16 percent, not to mention the negative
          effect on SEO. Those are some of the reasons i focus heavily on
          optimizing websites and apps for speed.
        </p>
      </div>
      <div className="about-page-col-image">
        <StyledBackgroundSection imageName="gtm" />
      </div>
    </div>

    <div className="about-page-row">
      <div className="about-page-col-image order-image">
        <StyledBackgroundSection imageName="computers" />
      </div>

      <div className="about-page-col container">
        <h1>My philosophy as a developer</h1>

        <p>
          I constantly put my self on the spot into unknown situations. This way
          i manage to learn new skills and improve faster. Even thought i have
          more experience in the front end i don't believe putting your skills
          into boxes makes you progress as a developer. I try to be good all
          around and when i don't have enough time to spend on specific
          technology i try to at least get a general idea. Everything is a tool
          in my opinion, it's really important for me to use the correct tool
          for the correct project and focus on delivering fast and reliable
          products.
        </p>
      </div>
    </div>

    <div className="about-page-row">
      <div className="about-page-col container">
        <h1>Current skill set</h1>
        <p>
          These are some of the technologies i've used over the years. Please{" "}
          <Link to="/contact-me">contact me</Link> to learn more about.
        </p>
        <div>
        <br></br>
        </div>
       
        <h2>Front end</h2>

        <div className="skills">
          <p>
            <strong>
              HTML - CSS (LESS,SCSS) - Javascript (React-Redux) - Typescript{" "}
            </strong>
          </p>
        </div>

        <h2>Back end</h2>
        <div className="skills">
          <p>
            <strong>C# - Node.js - PHP - AMPSCRIPT (Salesforce)</strong>
          </p>
        </div>

        <h2>Other</h2>
        <div className="skills no-margin">
          <p>
            <strong>
              Git - npm - yarn - gulp - cPanel - Plesk - SSL -
              Netlify - Gatsby - Wordpress - Umbraco - Agile - Scrum
            </strong>
          </p>
        </div>
      </div>
      <div className="about-page-col-image">
        <StyledBackgroundSection imageName="test" />
      </div>
    </div>
  </Layout>
)

export default AboutMePage
